import React, { useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"
import { Page } from "../../shared/Page/Page"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { TextButton } from "../../shared/Ui/TextButton/TextButton"
import media from "css-in-js-media"
import { transparentize } from "polished"
import { SEO } from "../../utils/SEO"

const ContainerAnimation = keyframes`
  0% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-12deg) rotateY(-15deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }

  25% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(7deg) rotateY(12deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
  75% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-10deg) rotateY(12deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
  100% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-12deg) rotateY(-15deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
`

const ScriptAnimation = keyframes`
  0% {
    transform: rotateY(0) perspective(700px) scale(1) translate(0, 0);
  }
  100% {
    transform: rotateY(-20deg) perspective(700px) scale(0.85) translate(150rem, 0px);
  }
`

const Container = styled.div`
  section.hero {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 120rem 0 200rem;

    ${media("<tablet")} {
      padding-bottom: 80rem;
    }

    ${media(">phone", "<desktop")} {
      flex-direction: column-reverse;
      gap: 110rem;

      > .left {
        text-align: center;
        align-items: center;
      }
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pre-header {
        display: flex;
        align-items: center;
        gap: 10rem;
        font-size: 14rem;
        color: ${({ theme }) => theme.text.lighter};
        font-family: "Gilroy-Regular", sans-serif;
        margin-bottom: 10rem;
      }

      h1 {
        font-size: 55rem;
        font-family: "Gilroy-Bold", sans-serif;
        color: ${({ theme }) => theme.text.dark};
        font-weight: unset;
        margin: 0;

        span {
          background: -webkit-linear-gradient(#60a5fa, #818cf8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      h2 {
        font-weight: normal;
        font-size: 20rem;
        color: rgb(76, 87, 115);
        line-height: 35rem;
        max-width: 490rem;
      }

      ${media("<phone")} {
        > h1:not(.desktop) {
          font-size: 35rem;
        }

        > h2:not(.desktop) {
          font-size: 20rem;
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 30rem;
      position: relative;
      perspective: 2000rem;
      perspective-origin: 50% 50%;
      overflow: visible;

      ${media("<tablet")} {
        display: none;
      }

      .back {
        position: absolute;
        top: 30rem;
        bottom: 30rem;
        left: 0;
        right: 0;
        margin: auto;
        width: 200rem;
        height: 200rem;
        opacity: 0;
        background: #bfdbfe;
        border-radius: 90%;
        transform-origin: center;
      }

      .anim-container {
        display: flex;
        perspective: 1500rem;
        overflow: visible;
        width: 100%;
        justify-content: center;
        transform-origin: center;

        .script {
          position: relative;
          height: 510rem;
          width: 450rem;
          overflow: visible;
          perspective: 1500rem;
          perspective-origin: center;
          transform-origin: center center;
          user-select: none;

            // animation: ${ScriptAnimation} 1 800ms;
          // animation-timing-function: ease-out;
          // animation-fill-mode: forwards;
          // animation-delay: 2s;

          .script-name {
            display: flex;
            align-items: center;
            gap: 10rem;
            font-size: 12rem;
            color: ${({ theme }) => theme.text.lighter};
            font-family: "Gilroy-Regular", sans-serif;
            position: absolute;
            z-index: 50;
            top: -15rem;
            left: 45rem;

            svg {
              width: 15rem;
              height: 15rem;
            }
          }

          .paper {
            position: absolute;
            inset: 0 0 0 0;
            margin: 10rem 10rem;
            background: #fff;
            border-radius: 15px;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
          }

          .text {
            z-index: 50;
            position: absolute;
            inset: 0 0 0 0;
            margin: 35rem 37rem;

            > div {
              > div {
                margin-bottom: 10rem;
              }

              > div.line {
                font-size: 13rem;
                line-height: 28rem;
                color: ${({ theme }) => theme.text.light};

                span {
                  padding: 5rem 10rem;
                  background: ${({ theme }) =>
                      transparentize(0.9, theme.primary)};
                  color: ${({ theme }) => theme.primary};
                  margin: 0 2rem;
                  border-radius: 5rem;
                }
              }

              > div.deferral {
                display: inline-flex;
                align-items: center;
                font-size: 12rem;
                padding: 5rem 15rem;
                border-radius: 5rem;
                font-weight: 600;
                color: ${({ theme }) => theme.primary};
                border: 1px solid ${({ theme }) => theme.borders.light};
              }

              > div.red-objection {
                margin-bottom: 20rem;

                > span {
                  background: #fff1f2;
                  color: #e11d48;
                  border-radius: 10rem 10rem 0 0;
                  padding: 5rem 10rem;
                  margin-left: 15rem;
                  font-size: 10rem;
                  font-weight: 600;
                  display: inline-block;
                }

                > div {
                  background: #fff1f2;
                  color: #e11d48;
                  border-radius: 10rem;
                  padding: 10rem 15rem;
                  font-size: 12rem;
                  line-height: 28rem;
                }

                .deferral {
                  display: inline-flex;
                  align-items: center;
                  padding: 0rem 10rem;
                  font-size: 12rem;
                  border-radius: 5rem;
                  font-weight: 500;
                  margin-top: 5rem;
                  color: #e11d48;
                  border: 1px solid #fecdd3;

                  svg {
                    height: 15rem;
                  }
                }
              }

              > div.buttons {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20rem;

                > div.deferral {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12rem;
                  padding: 10rem 15rem;
                  border-radius: 5rem;
                  font-weight: 600;
                  color: ${({ theme }) => theme.primary};
                  border: 1px solid ${({ theme }) => theme.borders.light};
                }
              }
            }
          }
        }

        .overlay {
          display: none;
          position: absolute;
          inset: 20rem auto 20rem 10rem;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          width: 260rem;

          div {
            width: 200rem;
            display: flex;
            flex-direction: column;

            .title {
              font-size: 18rem;
              font-family: "Gilroy-Regular", sans-serif;
              color: ${({ theme }) => theme.text.light};
              margin-bottom: 5rem;
              text-align: center;
            }

            .description {
              font-size: 14rem;
              line-height: 25rem;
              color: ${({ theme }) => theme.text.light};
              display: none;
            }

            .line {
              height: 1rem;
              width: 75rem;
              background: ${({ theme }) => theme.borders.light};
            }

            &.dynamic {
              .line {
                transform: translate(180px, 10px) rotate(19deg);
                z-index: 50;
                width: 90rem;
              }
            }

            &.actions {
              .line {
                transform: translate(210px, -13px) rotate(1deg);
                z-index: 50;
                width: 40rem;
              }
            }

            &.locations {
              .line {
                transform: translate(170px, -37px) rotate(-25deg);
                z-index: 50;
                width: 84rem;
              }
            }

            &.tangents {
              .line {
                transform: translate(182px, -37px) rotate(-32deg);
                z-index: 50;
                width: 74rem;
              }
            }

            &.navigate {
              .line {
                transform: translate(182px, -53px) rotate(-25deg);
                z-index: 50;
                width: 74rem;
              }
            }
          }
        }
      }
    }
  }
}

section.quick-feats {
  display: flex;
  align-items: center;
  margin: 0 -20rem;
  padding: 70rem 20rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), transparent);

  > div {
    display: flex;
    gap: 90rem;

    ${media("<desktop")} {
      > div {
        min-width: calc(50% - 40rem);
      }

      flex-wrap: wrap;
      gap: 50rem;
    }

    ${media("<=phone")} {
      flex-direction: column;

      > div:nth-of-type(even) {
        min-width: unset;
        align-items: flex-end;
        text-align: right;
      }
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 15rem;

      svg {
        width: 50rem;
        height: 50rem;
      }

      .title {
        font-size: 20rem;
        color: ${({ theme }) => theme.text.dark};
        font-family: "Gilroy-SemiBold", sans-serif;
      }

      .description {
        color: ${({ theme }) => theme.text.normal};
        font-size: 15rem;
        line-height: 30rem;
      }
    }
  }
}

section.start-right {
  margin: 0 -20rem;
  padding: 100rem 20rem 100rem;
  border-top: 1px solid ${({ theme }) => theme.borders.light};

  .leader {
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      margin: auto;
      gap: 20rem;
      text-align: center;

      .pretitle {
        color: ${({ theme }) => theme.primary};
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 20rem;
      }

      h3.title {
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 50rem;
        color: ${({ theme }) => theme.text.dark};
      }

      .subtitle {
        font-size: 18rem;
        line-height: 30rem;
        color: ${({ theme }) => theme.text.normal};
        max-width: 400rem;
      }
    }
  }

  .content {
    border-radius: 10rem;
    padding: 30rem 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .tile {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50rem 0;
      max-width: 1000rem;
      gap: 20rem;


      .left {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .icon {
          width: 30rem;
          height: 30rem;
          background: #eff6ff;
          border-radius: 90%;

          svg {
            width: 30rem;
            height: 30rem;
            transform: scale(0.95);
          }
        }

        h4 {
          font-family: "Gilroy-Black", sans-serif;
          font-size: 30rem;
          color: ${({ theme }) => theme.primary};
          margin: 0 0 10rem;
          background: -webkit-linear-gradient(#60a5fa, #818cf8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        h5 {
          margin: 10rem 0;
          font-size: 16rem;
          letter-spacing: 2rem;
          font-weight: 600;
          color: #60a5fa;
          text-transform: uppercase;
        }

        p {
          font-size: 16rem;
          line-height: 28rem;
          margin: 0;
          color: ${({ theme }) => theme.text.light};
        }
      }

      > .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .image {
          position: relative;
          z-index: 50;
          border-radius: 15rem;
          max-width: 300rem;

          &.shadow {
            box-shadow: #eff6ff 0px 50px 100px -20px,
            #c7d2fe 0px 30px 60px -30px;
          }
        }
      }

      ${media("<desktop")} {
        &:not(.ms) {
          flex-direction: column;
          text-align: center;
          align-items: center;
          gap: 50px;

          .left {
            max-width: 500rem;
            align-items: center;
          }
        }
      }
    }
  }
}
`

const FeaturesPhoneCalls = ({ location }) => {
    const isMounted = useRef(true)
    const animationFrameRef = useRef<number>()
    const animContainerRef = useRef<HTMLDivElement>(null)
    const valuesRef = useRef<{ x: number; y: number }>({ x: 2, y: 15 })

    useEffect(() => {
        const paint = () => {
            if (!isMounted.current) {
                return
            }

            const newX = Math.cos((valuesRef.current.x += 0.005)) * 8
            const newY = Math.cos((valuesRef.current.y += 0.003)) * 15

            animContainerRef.current.style.transform = `rotateX(${newX}deg) rotateY(${newY}deg)`

            animationFrameRef.current = requestAnimationFrame(paint)
        }

        if (animationFrameRef.current !== 0) {
            animationFrameRef.current = requestAnimationFrame(paint)
        }

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <Page
            constrainedHeader
            constrainedFooter
            constrainedCta
            location={location}
        >
            <SEO
                location={location}
                title={"Call scripts"}
                description={
                    "Build interactive and dynamic call scripts with rich media, maps, and full customisation."
                }
                ogImage={
                    "https://23shout.com/social/og/og-feature-call-scripts.png"
                }
            />
            <Container>
                <section className={"hero pageConstrained"}>
                    <div className={"left"}>
                        <div className={"pre-header"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect
                                        fill="none"
                                        height="24"
                                        width="24"
                                        x="0"
                                    />
                                </g>
                                <g>
                                    <g>
                                        <path d="M19.46,8l0.79-1.75L22,5.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,2c-0.18-0.39-0.73-0.39-0.91,0l-0.79,1.75 L16,4.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,8C18.72,8.39,19.28,8.39,19.46,8z M11.5,9.5L9.91,6 C9.56,5.22,8.44,5.22,8.09,6L6.5,9.5L3,11.09c-0.78,0.36-0.78,1.47,0,1.82l3.5,1.59L8.09,18c0.36,0.78,1.47,0.78,1.82,0l1.59-3.5 l3.5-1.59c0.78-0.36,0.78-1.47,0-1.82L11.5,9.5z M18.54,16l-0.79,1.75L16,18.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,22 c0.18,0.39,0.73,0.39,0.91,0l0.79-1.75L22,19.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,16 C19.28,15.61,18.72,15.61,18.54,16z" />
                                    </g>
                                </g>
                            </svg>
                            Included free in 23shout
                        </div>
                        <h1>
                            Build <span>interactive</span>, <span>dynamic</span>{" "}
                            call scripts for <span>your team</span>.
                        </h1>
                        <h2>
                            Scripts support dynamic values for contacts, rich
                            media, maps, and versioning for a complete history.
                        </h2>
                        <div>
                            <a href={"//app.23shout.com"} tabIndex={-1}>
                                <FlatButton color={"primary"} size={"larger"}>
                                    Get started for free <Chevron />
                                </FlatButton>
                            </a>
                        </div>
                    </div>
                    <div className={"right"}>
                        <div
                            className={"anim-container"}
                            ref={animContainerRef}
                        >
                            <div className={"overlay"}>
                                <div className={"dynamic"}>
                                    <div className={"text"}>
                                        <div className={"title"}>
                                            Dynamic values
                                        </div>
                                        <div className={"description"}>
                                            The view from above was quite
                                            sublime
                                        </div>
                                    </div>
                                    <div className={"line"} />
                                </div>
                                <div className={"actions"}>
                                    <div className={"text"}>
                                        <div className={"title"}>
                                            Take actions with a click
                                        </div>
                                        <div className={"description"}>
                                            The view from above was quite
                                            sublime
                                        </div>
                                    </div>
                                    <div className={"line"} />
                                </div>
                                <div className={"locations"}>
                                    <div className={"text"}>
                                        <div className={"title"}>
                                            Add locations
                                        </div>
                                        <div className={"description"}>
                                            The view from above was quite
                                            sublime
                                        </div>
                                    </div>
                                    <div className={"line"} />
                                </div>
                                <div className={"tangents"}>
                                    <div className={"text"}>
                                        <div className={"title"}>
                                            Highlight tangents
                                        </div>
                                        <div className={"description"}>
                                            The view from above was quite
                                            sublime
                                        </div>
                                    </div>
                                    <div className={"line"} />
                                </div>
                                <div className={"navigate"}>
                                    <div className={"text"}>
                                        <div className={"title"}>
                                            Navigate to other pages
                                        </div>
                                        <div className={"description"}>
                                            The view from above was quite
                                            sublime
                                        </div>
                                    </div>
                                    <div className={"line"} />
                                </div>
                            </div>
                            <div className={"script"}>
                                <div className={"script-name"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        fill="currentColor"
                                    >
                                        <path d="M3,6C2.45,6,2,5.55,2,5V2c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1S6.55,3,6,3H4v2C4,5.55,3.55,6,3,6z M17,2 c0,0.55,0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1h-3C17.45,1,17,1.45,17,2z M3,18c-0.55,0-1,0.45-1,1v3 c0,0.55,0.45,1,1,1h3c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1H4v-2C4,18.45,3.55,18,3,18z M17,22c0,0.55,0.45,1,1,1h3 c0.55,0,1-0.45,1-1v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2C17.45,21,17,21.45,17,22z M19,18c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V6 c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2V18z M9,9c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,8,9,8.45,9,9z M9,12c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,11,9,11.45,9,12z M9,15c0,0.55,0.45,1,1,1h4 c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,14,9,14.45,9,15z" />
                                    </svg>
                                    Prospecting script
                                </div>
                                <div className={"text"}>
                                    <div>
                                        <div className={"line"}>
                                            Hi <span>Janet</span>, this is{" "}
                                            <span>Marcus</span> from 7C Group,
                                            is now a good time to chat?
                                        </div>
                                        <div className={"deferral"}>
                                            Can't talk now <Chevron />
                                        </div>
                                        <div className={"line"}>
                                            Right now 7C is working on a new
                                            solution to problems in{" "}
                                            <span>Brisbane, Australia</span>, is
                                            that something you'd want more
                                            information on?
                                        </div>
                                        <div className={"red-objection"}>
                                            <span>If not interested</span>
                                            <div>
                                                Do you know anyone else in the
                                                industry who would be looking to
                                                generate more sales instead?
                                                <br />
                                                <div className={"deferral"}>
                                                    Offers alternate contact{" "}
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"line"}>
                                            Which one of those solutions
                                            interests you the most?
                                        </div>
                                        <div className={"buttons"}>
                                            <div className={"deferral"}>
                                                Seasonal reports
                                            </div>
                                            <div className={"deferral"}>
                                                Buyer intent data
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"paper"}></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={"quick-feats"}>
                    <div className={"pageConstrained"}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect fill="none" height="24" width="24" />
                                </g>
                                <g>
                                    <g>
                                        <path d="M8.79,9.24V5.5c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5v3.74c1.21-0.81,2-2.18,2-3.74c0-2.49-2.01-4.5-4.5-4.5 s-4.5,2.01-4.5,4.5C6.79,7.06,7.58,8.43,8.79,9.24z M14.29,11.71c-0.28-0.14-0.58-0.21-0.89-0.21h-0.61v-6 c0-0.83-0.67-1.5-1.5-1.5s-1.5,0.67-1.5,1.5v10.74l-3.44-0.72c-0.37-0.08-0.76,0.04-1.03,0.31c-0.43,0.44-0.43,1.14,0,1.58 l4.01,4.01C9.71,21.79,10.22,22,10.75,22h6.1c1,0,1.84-0.73,1.98-1.72l0.63-4.47c0.12-0.85-0.32-1.69-1.09-2.07L14.29,11.71z" />
                                    </g>
                                </g>
                            </svg>
                            <div className={"title"}>Interactive and smart</div>
                            <div className={"description"}>
                                Design the flow of your script with buttons that
                                take agents to other pages or run actions such
                                as scheduling callbacks for later.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect fill="none" height="24" width="24" />
                                    <path d="M19,3H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.11,3,19,3z M19,19H5V7h14V19z M16,12H8 c-0.55,0-1-0.45-1-1v0c0-0.55,0.45-1,1-1h8c0.55,0,1,0.45,1,1v0C17,11.55,16.55,12,16,12z M12,16H8c-0.55,0-1-0.45-1-1v0 c0-0.55,0.45-1,1-1h4c0.55,0,1,0.45,1,1v0C13,15.55,12.55,16,12,16z" />
                                </g>
                            </svg>
                            <div className={"title"}>Unique per call</div>
                            <div className={"description"}>
                                Assign scripts to queues to give your team what
                                they need at exactly the right time, with names,
                                locations, and other properties pre-filled
                                correctly.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect
                                        fill="none"
                                        height="24"
                                        width="24"
                                        x="0"
                                    />
                                </g>
                                <g>
                                    <g>
                                        <path d="M20.45,6l0.49-1.06L22,4.45c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49L20.45,2c-0.18-0.39-0.73-0.39-0.91,0l-0.49,1.06 L18,3.55c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L19.55,6C19.72,6.39,20.28,6.39,20.45,6z" />
                                        <path d="M8.95,6l0.49-1.06l1.06-0.49c0.39-0.18,0.39-0.73,0-0.91L9.44,3.06L8.95,2C8.78,1.61,8.22,1.61,8.05,2L7.56,3.06L6.5,3.55 c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L8.05,6C8.22,6.39,8.78,6.39,8.95,6z" />
                                        <path d="M19.55,13.5l-0.49,1.06L18,15.05c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49l0.49,1.06c0.18,0.39,0.73,0.39,0.91,0l0.49-1.06 L22,15.95c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49l-0.49-1.06C20.28,13.11,19.72,13.11,19.55,13.5z" />
                                        <path d="M17.71,9.12l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41l2.83,2.83 c0.39,0.39,1.02,0.39,1.41,0l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.21,11.21l-1.41-1.41l1.38-1.38l1.41,1.41 L14.21,11.21z" />
                                    </g>
                                </g>
                            </svg>
                            <div className={"title"}>Simple to build</div>
                            <div className={"description"}>
                                Enjoy a code free experience with our Word-like
                                editor, format, paste, and preview scripts in
                                realtime as your team will see them.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <rect fill="none" height="24" width="24" />
                                    <rect fill="none" height="24" width="24" />
                                </g>
                                <g>
                                    <g>
                                        <path d="M9,5v0.38c-0.83-0.33-1.72-0.5-2.61-0.5c-1.42,0-2.84,0.43-4.05,1.29C1.83,6.53,1.77,7.26,2.21,7.7l2.57,2.57h1.11v1.11 c0.86,0.86,1.98,1.31,3.11,1.36V15H7c-0.55,0-1,0.45-1,1v2c0,1.1,0.9,2,2,2h10c1.66,0,3-1.34,3-3V5c0-0.55-0.45-1-1-1H10 C9.45,4,9,4.45,9,5z M7.89,10.41V8.26H5.61L4.57,7.22C5.14,7,5.76,6.88,6.39,6.88c1.34,0,2.59,0.52,3.54,1.46l1.41,1.41l-0.2,0.2 c-0.51,0.51-1.19,0.8-1.92,0.8C8.75,10.75,8.29,10.63,7.89,10.41z M19,17c0,0.55-0.45,1-1,1s-1-0.45-1-1v-1c0-0.55-0.45-1-1-1h-5 v-2.59c0.57-0.23,1.1-0.57,1.56-1.03l0.2-0.2L15.59,14H17v-1.41l-6-5.97V6h8V17z" />
                                    </g>
                                </g>
                            </svg>
                            <div className={"title"}>Drafts and versioning</div>
                            <div className={"description"}>
                                Keep a complete history of what calls used what
                                version of your scripts, while building drafts
                                copies in isolation for testing for going live.
                            </div>
                        </div>
                    </div>
                </section>

                <section className={"start-right"}>
                    <div className={"pageConstrained"}>
                        <div className={"leader"}>
                            <div className={"title"}>
                                <div className={"pretitle"}>
                                    One click publishing
                                </div>
                                <h3 className={"title"}>
                                    Instant updates, smart features
                                </h3>
                                <div className={"subtitle"}>
                                    No more sharing PDFs and Google docs, test,
                                    author, and deploy instantly
                                </div>
                            </div>
                        </div>

                        <div className={"content"}>
                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>A/B testing built in</h5>
                                    <h4>
                                        Test new hypotheses and monitor call
                                        completions
                                    </h4>
                                    <p>
                                        Set new versions of your script to be
                                        tested in live calls, your agents will
                                        have a 50/50 chance of seeing your new
                                        copy. Monitor conversions with our{" "}
                                        <Link to={"/collaboration/analytics"}>
                                            <TextButton color={"primary"}>
                                                analytics offering
                                            </TextButton>
                                        </Link>{" "}
                                        and follow it's impact on your team.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/scripts/versions.png"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"constrained"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>

                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>More than text</h5>
                                    <h4>
                                        Embed rich media and interactive
                                        elements
                                    </h4>
                                    <p>
                                        Go beyond the paragraphs, inserting
                                        images, lists, headers, iFrames, and
                                        editable contact properties directly
                                        into the page.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/scripts/rich.png"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"constrained"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"leader"} style={{ marginTop: "100px" }}>
                        <div className={"title"}>
                            <div className={"pretitle"}>
                                More ways we're different
                            </div>
                        </div>
                    </div>
                    <section className={"quick-feats"}>
                        <div className={"pageConstrained"}>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                            x="0"
                                        />
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M19.46,8l0.79-1.75L22,5.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,2c-0.18-0.39-0.73-0.39-0.91,0l-0.79,1.75 L16,4.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,8C18.72,8.39,19.28,8.39,19.46,8z M11.5,9.5L9.91,6 C9.56,5.22,8.44,5.22,8.09,6L6.5,9.5L3,11.09c-0.78,0.36-0.78,1.47,0,1.82l3.5,1.59L8.09,18c0.36,0.78,1.47,0.78,1.82,0l1.59-3.5 l3.5-1.59c0.78-0.36,0.78-1.47,0-1.82L11.5,9.5z M18.54,16l-0.79,1.75L16,18.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,22 c0.18,0.39,0.73,0.39,0.91,0l0.79-1.75L22,19.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,16 C19.28,15.61,18.72,15.61,18.54,16z" />
                                        </g>
                                    </g>
                                </svg>
                                <div className={"title"}>Beautiful calling</div>
                                <div className={"description"}>
                                    We're not that solution that runs on Windows
                                    95, we're designed from the ground up to
                                    fill the void for a modern contact centre.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <rect fill="none" height="24" width="24" />
                                    <g>
                                        <path d="M19.83,7.5l-2.27-2.27c0.07-0.42,0.18-0.81,0.32-1.15c0.11-0.26,0.15-0.56,0.09-0.87C17.84,2.49,17.14,1.99,16.4,2 c-1.59,0.03-3,0.81-3.9,2l-5,0C4.46,4,2,6.46,2,9.5c0,2.25,1.37,7.48,2.08,10.04C4.32,20.4,5.11,21,6.01,21L8,21c1.1,0,2-0.9,2-2v0 h2v0c0,1.1,0.9,2,2,2l2.01,0c0.88,0,1.66-0.58,1.92-1.43l1.25-4.16l2.14-0.72c0.41-0.14,0.68-0.52,0.68-0.95V8.5c0-0.55-0.45-1-1-1 H19.83z M12,9H9C8.45,9,8,8.55,8,8v0c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1v0C13,8.55,12.55,9,12,9z M16,11c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1s1,0.45,1,1C17,10.55,16.55,11,16,11z" />
                                    </g>
                                </svg>
                                <div className={"title"}>
                                    No lock in contracts
                                </div>
                                <div className={"description"}>
                                    There's no tricks, we're confident you'll
                                    like 23shout. Subscriptions are billed
                                    monthly, and if you're not happy we'll even
                                    pro-rata your time back to you.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill={"url(#brandGradient)"}
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                    </g>
                                    <g>
                                        <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M19.46,9.12l-2.78,1.15 c-0.51-1.36-1.58-2.44-2.95-2.94l1.15-2.78C16.98,5.35,18.65,7.02,19.46,9.12z M12,15c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3 S13.66,15,12,15z M9.13,4.54l1.17,2.78c-1.38,0.5-2.47,1.59-2.98,2.97L4.54,9.13C5.35,7.02,7.02,5.35,9.13,4.54z M4.54,14.87 l2.78-1.15c0.51,1.38,1.59,2.46,2.97,2.96l-1.17,2.78C7.02,18.65,5.35,16.98,4.54,14.87z M14.88,19.46l-1.15-2.78 c1.37-0.51,2.45-1.59,2.95-2.97l2.78,1.17C18.65,16.98,16.98,18.65,14.88,19.46z" />
                                    </g>
                                </svg>
                                <div className={"title"}>Here to help</div>
                                <div className={"description"}>
                                    You're not left to figure it out, let's
                                    arrange a call anytime to help your team
                                    succeed with 23shout. No request is too
                                    large or small.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                        <path d="M9.78,11.16l-1.42,1.42c-0.68-0.69-1.34-1.58-1.79-2.94l1.94-0.49C8.83,10.04,9.28,10.65,9.78,11.16z M10.15,5.15 L7.35,2.35c-0.2-0.2-0.51-0.2-0.71,0L3.85,5.15C3.54,5.46,3.76,6,4.21,6h1.81C6.04,6.81,6.1,7.54,6.21,8.17l1.94-0.49 C8.08,7.2,8.03,6.63,8.02,6h1.78C10.24,6,10.46,5.46,10.15,5.15z M20.15,5.15l-2.79-2.79c-0.2-0.2-0.51-0.2-0.71,0l-2.79,2.79 C13.54,5.46,13.76,6,14.21,6h1.78c-0.1,3.68-1.28,4.75-2.54,5.88c-0.5,0.44-1.01,0.92-1.45,1.55c-0.34-0.49-0.73-0.88-1.13-1.24 L9.46,13.6C10.39,14.45,11,15.14,11,17c0,0,0,0,0,0h0v4c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-4c0,0,0,0,0,0 c0-2.02,0.71-2.66,1.79-3.63c1.38-1.24,3.08-2.78,3.2-7.37h1.8C20.24,6,20.46,5.46,20.15,5.15z" />
                                    </g>
                                </svg>
                                <div className={"title"}>
                                    Keep your data clean
                                </div>
                                <div className={"description"}>
                                    Setup call <strong>Outcomes</strong> with
                                    automated actions. Automatically schedule no
                                    answers to be called again in the future, or
                                    add numbers to Do Not Call lists.
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default FeaturesPhoneCalls
